import request from './request.js';
export function getUser(){
    var url = `/ding_user`;
    return request({
        method: 'get',
        url,
    }, true)  
}
// 获取pdf文件
export function getPDf(id){
    var url = `/file/pdf/${id}`
    return request({
        method: 'get',
        url,
    }, true);
}

// 获取任务列表
export function getTaskList(){
    var url = '/task'
    return request({
        method: 'get',
        url,
    }, true);
}

// 领取任务
export function takeTask(tid){
    var url = `/task/${tid}/take`;
    return request({
        method: 'post',
        url,
    }, true);
}

// 获取任务详情
export function getTaskDetail(id, tsid){
    var url = `/task/${id}?tsid=${tsid}`;
    return request({
        method: 'get',
        url,
    }, true);
}
// 获取课件
export function getLession(lid, tluid){
    var url = `/task/lesson_user/${tluid}?lid=${lid}`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 保存观看课件
export function uploadVideoProcess(tluid, data){
    var url = `/task/lesson_user/${tluid}/process`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}
// 观看完成 
export function finishLessonProcess(tluid, tid){
    var url = `/task/lesson_user/${tluid}/finish?tid=${tid}`;
    return request({
        method: 'post',
        url,
    }, true);
}

// 观看完成 
export function finishDocLessonProcess(tluid, tid){
    var url = `/task/lesson_user/doc/${tluid}/finish?tid=${tid}`;
    return request({
        method: 'post',
        url,
    }, true);
}

// 获取试卷
export function getExam(examid, tid){
    var url = `/task/exam/${examid}?tid=${tid}`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 获取试题
export function getQuestion(examid){
    var url = `/task/exam/questions?examid=${examid}`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 保存答案
export function saveQuestionResult(eqid, data){
    var url = `/task/exam/question/save?eqid=${eqid}`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}

// 交卷
export function finishExam(examid, tid, data){
    var url = `/task/exam/finish?examid=${examid}&taskid=${tid}`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}

export function finishCourseExam(examid, tid, data){
    var url = `/courseware/exam/finish?examid=${examid}&taskid=${tid}`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}

// 获取试题
export function getExamResult(tid, examid){
    var url = `/task/exam/result/${tid}?examid=${examid}`;
    return request({
        method: 'get',
        url,
    }, true);
}
// 获取试题
export function getCoursewareExamResult(cid, examid){
    var url = `/courseware/exam/result/${cid}?examid=${examid}`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 获取试题
export function getExamResultDetail(examid){
    var url = `/task/question/result/${examid}`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 获取我正在进行的课程
export function getOngoingLessons(){
    var url = `/task/lessons/ongoing`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getTaskLesson(keyword){
    var url = `/task/lessons/all?keyword=${keyword}`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getLessonDetail(id){
    var url = `/task/lesson/${id}`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getUntakedTaskCount(){
    var url = `/task/untaked_count`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getCoursewareList(params){
    var url = `/courseware`;
    return request({
        method: 'get',
        url,
        params
    }, true);
}
export function getHomeCoursewareList(params){
    var url = `/courseware/home`;
    return request({
        method: 'get',
        url,
        params
    }, true);
}

export function getCoursewareTypeList(){
    var url = `/courseware/type`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getCoursewareDetail(id){
    var url = `/courseware/${id}`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 保存观看课件
export function uploadViewProcess(cluid, data, params){
    var url = `/courseware/courseware_user/process/${cluid}`;
    return request({
        method: 'post',
        url,
        params,
        data,
    }, true);
}

// 观看完成 
export function finishViewProcess(cluid, cid, data){
    var url = `/courseware/courseware_user/finish/${cluid}?cid=${cid}`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}

// 观看完成 doc
export function finishViewDocProcess(cid,clid, data){
    var url = `/courseware/courseware_user/doc/finish?cid=${cid}&clid=${clid}`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}
export function getCoursewarePraise(cid){
    var url = `/courseware/praise/status?cid=${cid}`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getCoursewareCollection(cid){
    var url = `/courseware/collection/status?cid=${cid}`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getUserCollection(){
    var url = `/courseware/collection/list`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function updateCoursewarePraise(cid, data){
    var url = `/courseware/praise/update?cid=${cid}`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}

export function updateCoursewareCollection(cid, data){
    var url = `/courseware/collection/update?cid=${cid}`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}

export function getCoursewareVisit(cid){
    var url = `/courseware/visit/count?cid=${cid}`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getRanking(){
    var url = `/courseware/user/ranking`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getRecord(){
    var url = `/courseware/user/credit-record`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getCoursewareReply(cid){
    var url = `/courseware/reply/list?cid=${cid}`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function saveCoursewareReply(data){
    var url = `/courseware/reply/save`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}

export function getTagList(){
    var url = `/user/tag/list`;
    return request({
        method: 'get',
        url,
    }, true);
}

// erp
// 新建订单
export function createErpOrder(data){
    var url = `/erp/order/create`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}
// 修改订单数量
export function updateErpOrderNum(id, data){
    var url = `/erp/order/num/update/${id}`;
    return request({
        method: 'put',
        url,
        data,
    }, true);
}

// 获取订单列表
export function getErpOrderList(date){
    var url = `/erp/order/list/get?date=${date}`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function searchErpOrderList(keywords){
    var url = `/erp/order/list/search?keywords=${keywords}`;
    return request({
        method: 'get',
        url,
    }, true);
}

export function getErpOrderDetail(id){
    var url = `/erp/order/get/${id}`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 新建订单
export function createErpOrderComment(data){
    var url = `/erp/order/comment/create`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}
// 修改订单数量
export function deleteErpOrderCOmment(id){
    var url = `/erp/order/comment/del/${id}`;
    return request({
        method: 'delete',
        url,
    }, true);
}

// 新建订单
export function getPdfInfo(data, type){
    var url = `/erp/file/upload/pdf?type=${type}`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}
// 获取通知人列表
export function getNoticeUserList(){
    var url = `/erp/order/notice-user/list`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 获取当前任务
export function getMesTaskDetailStatus(taskid){
    var url = `/mes/order/task/${taskid}/detail-afoot`;
    return request({
        method: 'get',
        url,
    }, true);
}


// 获取配置的工序
export function getMesTaskStagList(){
    var url = `/mes/order/task/stage/dict`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 获取配置的暂停原因
export function getMesTaskExceptionList(){
    var url = `/mes/order/task/exception/dict`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 开始任务
export function updateMesTask(taskid, type, form){
    var url = `/mes/order/task/${taskid}/update2?type=${type}`;
    var data = { form };
    return request({
        method: 'put',
        url,
        data,
    }, true);
}

// 获取我参与的
export function getMesMyJoinTaskList(){
    var url = `/mes/order/task/my-join-list/get`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 获取我的工作状态
export function getMesMyTaskUser(){
    var url = `/mes/order/task/my-info/get`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 获取我的工时
export function getMesMyUserSectionList(params){
    var url = `/mes/order/task/my-section-list/get`;
    return request({
        method: 'get',
        url,
        params
    }, true);
}

export function getDingtalkTest(){
    var url = `/dingtalk/test`;
    return request({
        method: 'get',
        url,
    }, true);
}

// 绑定用户工作证
export function bindUserCard(data){
    var url = `/mes/order/task/workcard/bind-user`;
    return request({
        method: 'post',
        url,
        data
    }, true);
}
// 解绑用户工作证
export function releaseUserCard(data){
    var url = `/mes/order/task/workcard/release-user`;
    return request({
        method: 'post',
        url,
        data
    }, true);
}

// 通过工作证获取员工信息
export function getUserInfoWithCard(params){
    var url = `/mes/order/task/workcard/user/get`;
    return request({
        method: 'get',
        url,
        params
    }, true);
}

// 获取我的考勤信息
export function getMyAttendance(params){
    var url = `/hr/attendance/list/get`;
    return request({
        method: 'get',
        url,
        params
    }, true);
}

// 获取我的考勤明细
export function getMyAttendanceDetail(id){
    var url = `/hr/attendance/detail/${id}/get`;
    return request({
        method: 'get',
        url
    }, true);
}

// 获取我的待办
export function getMyAttendanceTodoDetail(batch){
    var url = `/hr/attendance/todo/get?batch=${batch}`;
    return request({
        method: 'get',
        url
    }, true);
}
// 获取我的考勤明细
export function confirmMyAttendance(id, data){
    var url = `/hr/attendance/confirm/${id}/update`;
    return request({
        method: 'put',
        url,
        data
    }, true);
}

// 获取我的未确认考勤信息
export function getMyUnConfirmedAttendanceList(params){
    var url = `/hr/attendance/unconfirmed/list/get`;
    return request({
        method: 'get',
        url,
        params
    }, true);
}

// 获取配置的暂停原因
export function getMesTaskStagePointList(){
    var url = `/mes/order/task/stage/point1/dict`;
    return request({
        method: 'get',
        url,
    }, true);
}
// 保存关键点
export function updateMesTaskStagePoint(spid, data){
    var url = `/mes/order/task/stage/point/${spid}/save`;
    return request({
        method: 'post',
        url,
        data,
    }, true);
}